<template>
  <v-hover
    v-slot="{ hover }"
  >
    <v-card
      :elevation="hover ? 6 : 2"
      :ripple="false"
      height="160px"
      :width="$vuetify.breakpoint.mobile ? '' : '300px'"
      :class="$vuetify.breakpoint.mobile ? 'mx-auto' : ''"
      @click.stop="openOverview()"
    >
      <v-row class="mt-1 ml-1 justify-space-around">
        <v-col
          cols="10"
        >
          <v-tooltip
            top
            color="primary"
          >
            <template v-slot:activator="{ on, attrs }">
              <div
                v-bind="attrs"
                class="text-left thick mt-n1"
                v-on="on"
              >
                {{ caseData.caseName }}
              </div>
            </template>
            <span>{{ caseData.caseName }}</span>
          </v-tooltip>
        </v-col>
        <v-col
          cols="2"
        >
          <v-tooltip
            top
            color="primary"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                x-small
                v-bind="attrs"
                style="position: absolute; top: 15px; right: 27px;"
                v-on="on"
                @click.stop="removePinFromDashboard(caseData)"
              >
                <v-icon>
                  mdi-pin
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t('actions|remove_from_pinboard') }}</span>
          </v-tooltip>
        </v-col>
      </v-row>
      <div class="case-data ml-4 mt-n4 mb-n1">
        <v-icon
          color="primary"
          small
        >
          mdi-briefcase-edit
        </v-icon>
        {{ $t('cases|case') }}
      </div>
      <div class="case-data ml-4">
        {{ $t('clients|client') }}:
        <span v-if="caseData && caseData.client && !caseData.client.realClient">
          <span v-if="caseData.client.clientType === 'individual'">{{ caseData.client.clientData.given_names + ' ' + caseData.client.clientData.surname }}</span>
          <span v-if="caseData.client.clientType === 'company'">{{ caseData.client.clientData.company_name }}</span>
        </span>
        <span v-if="caseData && caseData.client && caseData.client.realClient">
          <span v-if="caseData.client.clientType === 'individual'">{{ caseData.client.clientData.given_names ? caseData.client.clientData.given_names : 'not added' }} {{ caseData.client.clientData.surname ? caseData.client.clientData.surname : 'not added' }}</span>
          <span v-if="caseData.client.clientType === 'company'">{{ caseData.client.clientData.company_name }}</span>
        </span>
      </div>
      <div
        class="case-data ml-4 mt-n2"
      >
        {{ $t('common|date_created') }}: {{ caseData.createdAt | date }}
      </div>
      <v-tooltip
        top
        color="primary"
      >
        <template v-slot:activator="{ on, attrs }">
          <div
            v-if="account.accountType === 'lawyer'"
            class="case-data ml-4 mt-n2"
            v-bind="attrs"
            v-on="on"
          >
            {{ $t('common|date_updated') }}: {{ caseData.updatedAt | dateTime }}
          </div>
        </template>
        <span>{{ caseData.updatedAt | dateTime }}</span>
      </v-tooltip>
      <v-tooltip
        top
        color="primary"
      >
        <template v-slot:activator="{ on, attrs }">
          <div
            class="case-data ml-4 mt-n2"
            v-bind="attrs"
            v-on="on"
          >
            {{ $t('common|created_by') }}: {{ caseData.createdBy.email }}
          </div>
        </template>
        <span>{{ caseData.createdBy.email }}</span>
      </v-tooltip>
      <v-card-actions>
        <v-btn
          text
          x-small
          color="deep-purple accent-4"
          class="mt-n5"
          @click.stop="viewCase(caseData)"
        >
          {{ $t('actions|open') }}
        </v-btn>
        <v-spacer />
        <v-btn
          text
          icon
          x-small
          color="error"
          style="position: absolute; bottom: 6px; right: 27px;"
          @click.stop="getCaseSharedWithLength(caseData) <= 1 ? deleteCase(caseData) : removeCase(caseData)"
        >
          <v-icon v-text="icons.trash" />
        </v-btn>
        <v-col
          xs1
          md1
          lg1
        >
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-sheet
                :attrs="attrs"
                :color="match(caseData) ? 'primary' : 'accent'"
                class="delete stripe-position mr-2 my-auto"
                min-width="9"
                max-width="10"
                height="80%"
                v-on="on"
              />
            </template>
            <span v-if="match(caseData)">{{ $t('clients|shared_with_client') }}</span>
            <span v-else>{{ $t('clients|not_shared_with_client') }}</span>
          </v-tooltip>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-hover>
</template>

<script>
import { caseStatuses } from '@/utils/index'
import CaseCardMixin from './CaseMixins/CaseCardMixin'
import { mdiTrashCanOutline, mdiChevronDown, mdiPinOutline } from '@mdi/js'

export default {
  mixins: [CaseCardMixin],
  data () {
    return {
      icons: {
        trash: mdiTrashCanOutline,
        arrowDown: mdiChevronDown,
        pin: mdiPinOutline
      },
      caseStatuses
    }
  }
}
</script>

<style scoped>
.actions-position {
  position: absolute;
  bottom: 0px;
}
.stripe-position {
  position: absolute;
  bottom: 15px;
}
.delete {
  position: absolute;
  right: 0;
}
.thick {
  font-size: 15px;
  font-weight: bold;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 240px;
  overflow: hidden;
}
.case-data {
  font-size: 14px;
  font-weight: lighter;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 240px;
}
/* .case-data:hover {
  overflow: auto;
} */
</style>
